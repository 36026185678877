@use "sass:map";

// copied from BOOTSTRAP - Breakpoints
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px)
$breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px) !default;
@forward "breakpoints";

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');

// bootstrap variables
$enable-print-styles: false;

$top-spacing: 1rem !default;

// copied from BOOTSTRAP - Font
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// copied from BOOTSTRAP - Colors
$color-background: #f8f8f8 !default;
$color-font: #222222 !default;
$color-border: rgba(0, 0, 0, 0.2) !default;

// copied from BOOTSTRAP - $spacer
$spacer: 1rem !default;
$top-spacing: $spacer;
$section-spacing: $top-spacing;


// PDF UPDATES
body.pdf,
body.pdf h1,
body.pdf h2,
body.pdf h3,
body.pdf h4,
body.pdf h5,
body.pdf h6 {
    font-family: Arial !important;
}
body.pdf h1,
body.pdf h2,
body.pdf h3,
body.pdf h4,
body.pdf h5,
body.pdf h6 {
    font-weight: 550;
}

@page {
    size: A4 portrait;
}

html * {
    box-sizing: border-box;
}

body {
    font-family: $font-family-sans-serif;
    background-color: $color-background;
    color: $color-font;
    margin: 0;
    font-size: 1rem;
}

body.page {
    min-height: 100vh;
}

p {
    margin: .75em 0 0 0;
}

h1,
h2,
h3,
h4,
h5,
h5 {
    font-family: 'Josefin Sans', $font-family-sans-serif;
    margin: 1em 0 .3em 0;
    margin-top: min(1em, 1.4rem);
}
h1, h2, h3 {
    margin-top: .7em;
    margin-top: min(.7em, 1.4rem);
}

h1 { font-size: 2.6rem; }
h2 { font-size: 1.4rem; }
h3 { font-size: 1.2rem; }
h4 { font-size: 1rem; }
h5 { font-size: .9rem; }
h6 { font-size: .8rem; }

body.pdf h1 { font-size: 2.5rem; }
body.pdf h2 { font-size: 1.3rem; }
body.pdf h3 { font-size: 1.1rem; }
body.pdf h4 { font-size: 0.95rem; }
body.pdf h5 { font-size: 0.85rem; }
body.pdf h6 { font-size: 0.8rem; }

ul, ol {
    font-size: 0.8rem;
    margin: 0;
    padding-left: 1.2rem;
}

.list-section {
    margin-top: calc($section-spacing / 3);
    + .list-section {
        margin-top: calc($section-spacing * 3 / 4);
    }
    > ul {
        > li {
            line-height: 1.4;
        }
        > li + li {
            margin-top: calc($spacer / 5);
        }
        p {
            margin-top: 0;
            margin-bottom: .2rem;
        }
        // sub-list
        h4 {
            font-size: 1rem;
            margin-bottom: .2rem;
        }
    }
}
